<template>
    <div>
      <b-row>
        <b-col xl="2" md="4" sm="6">
          <b-link @click="uploadFile">
            <b-card class="text-center">
              <b-avatar class="mb-1" variant="light-info" size="45">
                <feather-icon size="21" icon="FileIcon" />
              </b-avatar>
              <div class="truncate">
                <h4 class="mb-25 font-weight-bolder">Upload CNIC List to change User type to Retired</h4>
              </div>
            </b-card>
          </b-link>
        </b-col>
      </b-row>
      <CnicListUploadModal 
        @modalClosed="onModalClosedUpload"
        :key="`upload-${CnicListUploadModalCount}`"
        />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import CnicListUploadModal from "@/components/admin/CnicListUploadModal.vue";
  export default {
    components: {
        CnicListUploadModal
    },
    data() {
      return {
        CnicListUploadModalCount: 0,
      };
    },
    mixins: [util],
  
    async mounted() {},
    methods: {
      ...mapActions({
        
      }),
      async uploadFile() {
        this.CnicListUploadModalCount += 1;
        this.$nextTick(() => {
          this.$bvModal.show("cnic-list-upload-modal");
        });
      },
      onModalClosedUpload() {
        this.$bvModal.hide("cnic-list-upload-modal");
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
      }),
    },
  };
  </script>
  
  <style></style>
  