<template>
  <b-modal
    id="cnic-list-upload-modal"
    title="Upload Users Data"
    centered
    hide-footer
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Upload CNIC List to change User type to Retired</h2>
    </template>
    <validation-observer ref="userUploadFormValidation">
      <b-form @submit.prevent>
        <validation-provider #default="{ errors }" name="File">
            <b-form-group
              label="Upload Excel File"
              label-for="file"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-file
                ref="file-input"
                v-model="excelFile"
                @change="handleBenefitFileUpload($event)"
                accept=".xlsx"
                size="md"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- <b-form-group label="File" label-for="attachments">
            <b-form-file
              id="attachments"
              placeholder="Choose files or drop them here..."
              drop-placeholder="Drop file here..."
              multiple
              v-model="uploadedFiles"
              :accept="acceptedFileTypes"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group> -->
        <b-form-group>
          <b-button
            variant="outline-success"
            type="submit"
            @click="uploadExcel"
            class="text-truncate"
          >
            <feather-icon icon="UploadIcon" class="mr-50" />
            Upload Excel File
          </b-button>  
          <!-- <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1 float-right"
            @click="validationForm"
          >
            Submit
          </b-button> -->
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import util from "@/util.js";
import axios from "axios";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  mixins: [util],
  data() {
    return {
      required,
      uploadedFiles: [],
      acceptedFileTypes: ".war",
      excelFile: null,
    };
  },
  methods: {
    ...mapActions({
      uploadData: "appData/uploadData",
      uploadCnicList: "appData/uploadCnicList",
    }),
    // async validationForm() {
    //   const success = await this.$refs.userUploadFormValidation.validate();
    //   if (success) {
    //     let invalidType = false;
    //     for (let i = 0; i < this.uploadedFiles.length; i++) {
    //       if (
    //         this.acceptedFileTypes.indexOf(
    //           this.uploadedFiles[i].name.split(".")[1]
    //         ) < 0
    //       ) {
    //         invalidType = true;
    //         break;
    //       }
    //     }
    //     if (!invalidType) {
    //       await this.submit();
    //     }
    //   }
    // },
    handleBenefitFileUpload(event) {
      if (event.target.files.length > 0) {
        this.excelFile = event.target.files[0];
      } else {
        console.error("No file selected.");
      }
    },
    async uploadExcel() {
      try {
        if (this.excelFile) {
          this.show = true;
          let formData = new FormData();
          formData.append("file", this.excelFile);
          const res = await this.uploadCnicList(formData);
          if (res.status === 200) {
            console.log('res.status')
            console.log(res.status)
            console.log('res.status')
            this.$swal({
              title: res.data.msg,
              icon: "success",
              timer: 1500,
            });
          }
          this.show = false;
        }
        // }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
      this.$emit("modalClosed");
    },
    // async submit() {
    //   try {
    //     let formData = new FormData();
    //     this.uploadedFiles.map((file, index) => {
    //       formData.append(`file${index}`, file);
    //     });

    //     const res = await this.uploadData(formData);

    //     if (res.status === 200) {
    //       this.$swal({
    //         title: "Data Uploaded Successfully",
    //         icon: "success",
    //         timer: 1500,
    //       });
    //     }
    //   } catch (error) {
    //     this.displayError(error);
    //   }
    //   this.selectedApplication = null;
    //   this.uploadedFiles = [];
    //   this.$emit("modalClosed");
    // },
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser" }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
